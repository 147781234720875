<template>
  <div class="d-flex flex-column fill-height">
    <v-row>
      <v-col offset="1" cols="10" lg="10" md="10" sm="10">
        <apexchart type="bar" height="150" :options="chartOptions" :series="chartData"></apexchart>
      </v-col>
    </v-row>
    <!-- <div class="d-flex align-center align-self-center pa-2">
        <devices-chart
          :deviceOnlineCount="devicesStore.devicesCounter.deviceOnlineCount"
          :deviceOfflineCount="devicesStore.devicesCounter.deviceOfflineCount"
          size="200px">
        </devices-chart>
      </div> -->
    <v-responsive max-height="calc(100% - 168px)">
      <div class="d-flex align-center align-self-center" style="flex: 1; width: 100%">
        <div style="width: 100%">
          <v-text-field
            class="filter-field"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                v-for="(action, index) in actions"
                :key="index"
                @click="action.action(item)"
              >
                <v-icon>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-responsive>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    store: Object,
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      items: [],
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          filterable: true,
          value: 'name',
        },
        { text: 'Estatus', filterable: true, value: 'statusTitle' },
        { text: 'Dirección', filterable: true, value: 'address' },
        { text: 'Camaras', filterable: true, value: 'numberOfCameras' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    }
  },
  created() {
    this.busesStore = this.store;
    this.items = this.store.getRecords()
    this.store.subscribe((item) => {
      const index = this.items.findIndex((it) => it.id === item.id)
      if (index === -1) {
        this.items.push(item)
      } else {
        this.items[index] = item
      }
    })
  },
  computed: {
    chartData() {
      const total = this.busesStore.busesCounter.busOnlineCount
        + this.busesStore.busesCounter.busOfflineCount;
      return [
        {
          name: 'Trasnporte Urbano',
          data: [
            total,
            this.busesStore.busesCounter.busOnlineCount,
            this.busesStore.busesCounter.busOfflineCount,
          ],
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['Total', 'En Linea', 'Fuerda de Linea'],
        },
        colors: [
          ({ dataPointIndex }) => {
            switch (dataPointIndex) {
              case 0:
                return '#1E3E8B';
              case 1:
                return '#126300';
              case 2:
                return '#C70039';
              default:
                return '#1E3E8B';
            }
          },
        ]
      }
    },
    left() {
      return window.innerWidth / 2 - this.width / 2;
    },
    top() {
      return window.innerHeight / 2 - this.height / 2;
    },
  },
}
</script>
<style lang="scss" scoped>
  .filter-field {
    padding: 20px
  }
</style>
